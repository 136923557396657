import { RadioOption } from '@app/components/forms'
import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CaseFormFields } from '../../types'
import { useTranslation } from 'react-i18next'
import './SecretCaseTypeRadioControl.scss'
import Collapse from 'react-bootstrap/Collapse'

export type SecretCaseOption = RadioOption & {
  body: string
  isActive?: boolean
  stages: string
  name: string
  refinements?: string | null
  warranty: string
  freeStudy: string
  freeShipping: string
  cancellation: string
  priceOneArch: number | string
  priceBothArch: number | string
}

type SecretCaseTypeRadioControlProps = {
  options: SecretCaseOption[]
  required: boolean
}

const useIsMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 992)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 992)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobileView
}

export const SecretCaseTypeRadioControl: FC<
  SecretCaseTypeRadioControlProps
> = ({ options, required }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<CaseFormFields>()
  const isMobileView = useIsMobileView()
  const [openIndexes, setOpenIndexes] = useState<Record<number, boolean>>({})

  return (
    <div className="secret-case-type-radio">
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <>
            {options.map((opt, i) => {
              const lowerName = opt.name.toLowerCase()
              const isLastElement = i === options.length - 1
              const handleSelect = () =>
                opt.isActive && field.onChange(opt.value)
              const isOpen = !!openIndexes[i]

              return (
                <React.Fragment key={opt.value.toString()}>
                  <div
                    className={clsx('secret-case-type-radio__control', {
                      'secret-case-type-radio__control--checked':
                        field.value === opt.value,
                      'secret-case-type-radio__control--hidden':
                        isLastElement && !isMobileView,
                    })}
                  >
                    <div
                      className={clsx(
                        'secret-case-type-radio__header',
                        `secret-case-type-radio__header--${lowerName}`,
                      )}
                    >
                      <div className="secret-case-type-radio__header-content">
                        <h3 className="secret-case-type-radio__title">
                          {opt.label}
                        </h3>
                        <p className="secret-case-type-radio__subtitle">
                          {opt.name}
                        </p>
                      </div>
                    </div>

                    <label
                      className={clsx('secret-case-type-radio__label', {
                        'secret-case-type-radio__label--required': required,
                      })}
                    >
                      <input
                        type="radio"
                        data-testid={`case-type-${String(opt.value).toLowerCase().replace(/_/g, '-')}`}
                        className="secret-case-type-radio__input"
                        name={field.name}
                        checked={field.value === opt.value}
                        required={required}
                        disabled={!opt.isActive}
                        onChange={() => field.onChange(opt.value)}
                        onBlur={field.onBlur}
                      />
                    </label>

                    <div className="secret-case-type-radio__content">
                      <p className="secret-case-type-radio__body">{opt.body}</p>
                      <ul className="secret-case-type-radio__features">
                        <li>{opt.stages}</li>
                        {opt.refinements && <li>{opt.refinements}</li>}
                        <li>{opt.freeStudy}</li>
                        <li>{opt.warranty}</li>
                        <li>{opt.cancellation}</li>
                      </ul>

                      <button
                        className="secret-case-type-radio__select-button btn btn-primary"
                        type="button"
                        disabled={!opt.isActive}
                        onClick={handleSelect}
                      >
                        {t('cases.case-type.button')}
                      </button>

                      <div className="secret-case-type-radio__footer">
                        {isOpen ? (
                          <button
                            type="button"
                            className="secret-case-type-radio__close-btn"
                            onClick={() =>
                              setOpenIndexes(prev => ({ ...prev, [i]: false }))
                            }
                            aria-label="Cerrar tarifa"
                          >
                            ×
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn"
                            disabled={!opt.isActive}
                            onClick={() =>
                              setOpenIndexes(prev => ({
                                ...prev,
                                [i]: !prev[i],
                              }))
                            }
                            aria-expanded={!!openIndexes[i]}
                          >
                            {t('cases.case-type.check-tariff')}
                          </button>
                        )}
                        <Collapse in={!!openIndexes[i]}>
                          <div className="secret-case-type-radio__tariff">
                            <div className="secret-case-type-radio__tariff-prices">
                              <div className="secret-case-type-radio__tariff-item">
                                <span className="secret-case-type-radio__tariff-price">
                                  {opt.priceOneArch}
                                </span>
                                <span className="secret-case-type-radio__tariff-label">
                                  {t('cases.case-type.one-arch')}
                                </span>
                              </div>
                              <div className="secret-case-type-radio__tariff-item">
                                <span className="secret-case-type-radio__tariff-price">
                                  {opt.priceBothArch}
                                </span>
                                <span className="secret-case-type-radio__tariff-label">
                                  {t('cases.case-type.both-arch')}
                                </span>
                              </div>
                            </div>
                            <div className="secret-case-type-radio__tariff-tax">
                              {t('cases.case-type.tax')}
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>

                  {isLastElement && !isMobileView && (
                    <div className="secret-case-type-radio__control--alternative">
                      <div className="secret-case-type-radio__header">
                        <div className="secret-case-type-radio__header-content">
                          <h3 className="secret-case-type-radio__title">
                            {opt.label}
                          </h3>
                          <p className="secret-case-type-radio__subtitle">
                            {opt.name}
                          </p>
                          <p className="secret-case-type-radio__body">
                            {opt.body}
                          </p>
                        </div>
                      </div>

                      <div className="secret-case-type-radio__features-container">
                        <ul className="secret-case-type-radio__features">
                          <li>{opt.stages}</li>
                          {opt.refinements && <li>{opt.refinements}</li>}
                          <li>{opt.warranty}</li>
                        </ul>
                      </div>

                      <div className="secret-case-type-radio__button-container">
                        <button
                          className="secret-case-type-radio__select-button btn btn-primary"
                          type="button"
                          disabled={!opt.isActive}
                          onClick={handleSelect}
                        >
                          {t('cases.case-type.button')}
                        </button>
                      </div>

                      <div className="secret-case-type-radio__footer">
                        {isOpen ? (
                          <button
                            type="button"
                            className="secret-case-type-radio__close-btn"
                            onClick={() =>
                              setOpenIndexes(prev => ({ ...prev, [i]: false }))
                            }
                            aria-label={t('cases.case-type.close-tariff')}
                          >
                            ×
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn"
                            disabled={!opt.isActive}
                            onClick={() =>
                              setOpenIndexes(prev => ({
                                ...prev,
                                [i]: !prev[i],
                              }))
                            }
                            aria-expanded={!!openIndexes[i]}
                          >
                            {t('cases.case-type.check-tariff')}
                          </button>
                        )}
                        <Collapse in={!!openIndexes[i]}>
                          <div className="secret-case-type-radio__tariff">
                            <div className="secret-case-type-radio__tariff-prices">
                              <div className="secret-case-type-radio__tariff-item">
                                <span className="secret-case-type-radio__tariff-price">
                                  {opt.priceOneArch}
                                </span>
                                <span className="secret-case-type-radio__tariff-label">
                                  {t('cases.case-type.one-arch')}
                                </span>
                              </div>
                              <div className="secret-case-type-radio__tariff-item">
                                <span className="secret-case-type-radio__tariff-price">
                                  {opt.priceBothArch}
                                </span>
                                <span className="secret-case-type-radio__tariff-label">
                                  {t('cases.case-type.both-arch')}
                                </span>
                              </div>
                            </div>

                            <div className="secret-case-type-radio__tariff-tax">
                              {t('cases.case-type.tax')}
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      />
    </div>
  )
}
