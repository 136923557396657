import {
  TableColumnType,
  TableConfigColType,
  TableRowType,
} from '@app/components/table/types'
import { TFunction } from 'i18next'
import { Invoice } from '@app/services/invoices/types'
import { AiOutlineDownload } from 'react-icons/ai'
import { Tooltip } from '@app/components/tooltip/Tooltip'
import { downloadDocument, getEnumKeyByValue } from '@app/utils'
import clsx from 'clsx'
import { PaymentMethods } from '@app/services/billing-address/types'

export const columnConfig: TableConfigColType[] = [
  {
    target: 'clinic',
    hideMobile: true,
    orderable: false,
  },
  {
    target: 'createdAt',
    hideMobile: true,
  },
  {
    target: 'paymentMethod',
    hideMobile: true,
  },
  {
    target: 'dueDate',
    hideMobile: true,
  },
  {
    target: 'paid',
    hideMobile: true,
  },
  {
    target: 'actions',
    orderable: false,
  },
]

export const getTableColumns = (t: TFunction): TableColumnType[] => [
  {
    id: 'invoiceNumber',
    title: t('invoices.number-invoice'),
  },
  {
    id: 'clinic',
    title: t('cases.clinic'),
  },
  {
    id: 'total',
    title: t('invoices.tax'),
  },
  {
    id: 'createdAt',
    title: t('cases.createdAt'),
  },
  {
    id: 'paymentMethod',
    title: t('invoices.payment-method'),
  },
  {
    id: 'dueDate',
    title: t('invoices.expiration'),
  },
  {
    id: 'paid',
    title: t('invoices.pay'),
  },
  {
    id: 'actions',
    title: t('invoices.actions'),
  },
]

export const getTableRows = (
  invoices: Invoice[],
  t: TFunction,
  formatDate: (value: string | Date | undefined) => string,
): TableRowType[] =>
  invoices?.map(data => {
    return {
      name: data.invoiceNumber,
      clinic: data.businessName.name,
      tax: `${data.totals.totalWithTax} €`,
      createdAt: formatDate(data.createdAt),
      paymentMethod: (
        <Tooltip
          id={data.id}
          title={t(
            `billing-address.payment-methods.${getEnumKeyByValue(PaymentMethods, data.paymentMethod)}`,
          )}
        >
          <div className="text-truncate">
            {t(
              `billing-address.payment-methods.${getEnumKeyByValue(PaymentMethods, data.paymentMethod)}`,
            )}
          </div>
        </Tooltip>
      ),
      dueDate: formatDate(data.dueDate),
      paid: (
        <span
          className={clsx(
            `StatusBadge rounded-pill badge StatusBadge-${data.paid ? 'done' : 'pending'}`,
          )}
        >
          {t(`invoices.${data.paid ? 'paid' : 'pending'}`)}
        </span>
      ),
      actions: (
        <button
          className="btn btn-primary btn-sm"
          disabled={!data.url}
          type="button"
          onClick={() =>
            downloadDocument(data.url, data.invoiceNumber + '.pdf')
          }
        >
          <AiOutlineDownload />
          <span className="d-none d-sm-inline">
            {t(`cases.multimedia.download`)}
          </span>
        </button>
      ),
    }
  })
